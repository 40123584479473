<template>
<div class="screenHeight" v-bind:class="{changePosition: toggleClass}">
    <section class="login" v-bind:class="{changePosition: toggleClass}">
        <img src="../assets/BalkanRoadsLogoSimbol.png" alt="Balkan roads top image" class="topPictureTablet" />
        <div class="container">
            <div class="progressContainer">
                <p>STEP 2 OF 3</p>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" style="width: 66%" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <form @submit.prevent="stepThree">

                <div class="form-group">
                    <label class="form-check-label" for="teamMem">Team members</label>
                    <div v-if="newMember === true">
                        <div class="listItem text-dark mb-3" v-for="(member, index) in members" :key="index">
                            <span>{{ member }}</span>
                            <a href="#" class="pl-3" @click="remove(index)">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="19" viewBox="0 0 15 19">
                                    <defs>
                                        <clipPath id="clip-Delete">
                                            <rect width="15" height="19" />
                                        </clipPath>
                                    </defs>
                                    <g id="Delete" clip-path="url(#clip-Delete)">
                                        <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.439,21.406c-.076-.076,11.242-.106,11.271,0V8.257H8.439ZM20.649,5.439H17.362L16.423,4.5h-4.7l-.939.939H7.5V7.318H20.649Z" transform="translate(-6.575 -3.5)" fill="#707070" />
                                    </g>
                                </svg>

                            </a>
                        </div>
                    </div>
                    <input type="text" class="form-control" id="teamMem" name="member" v-model="newItem" placeholder="Alan Ford" :class="{temMemDisable: disableAdd}">
                </div>

                <div class="addTeamMember pt-3">
                    <a href="#" class="signinLink" :class="{disable: disableAdd}" @click="addMember(true)">&#43; ADD TEAM MEMBER</a>
                </div>

                <div class="positionBottomButton form-group text-center" v-bind:class="{changePosition: toggleClass}">
                    <button class="btn btn-secondary w-100 text-uppercase">NEXT STEP</button>
                </div>
            </form>

        </div>
    </section>
    <!--<div class="positionBottom text-center pb-2" v-bind:class="{changePosition: toggleClass}"><a href="#" class="signinLink">Terms &#38; Conditions</a></div>-->
</div>
</template>

<script>
export default {
    data() {
        return {
            members: [],
            newItem: "",
            state: true,
            newMember: false,
            toggleClass: false,
            disableAdd: false,
        }
    },
    methods: {
        addMember(newState) {
            if (this.newItem !== "") {
                this.members.push(this.newItem);
                this.newItem = "";
                this.newMember = newState;
                if(this.members.length === 5){
                    this.disableAdd = true;
                }else{
                    this.disableAdd = false;
                }
            }

            if(this.members.length >= 3){
                this.toggleClass = true;
            }
            else{
                this.toggleClass = false;
            }

        },
        remove(index) {
            this.members.splice(index, 1);

            if(this.members.length === 5){
                this.disableAdd = true;
            }else{
                this.disableAdd = false;
            }

            if(this.members.length >= 3){
                this.toggleClass = true;
            }
            else{
                this.toggleClass = false;
            }
        },
        stepThree() {
            this.$emit('stepThree', this.members);
        }
    },
}
</script>

<style lang="scss" scoped>
.screenHeight.changePosition{
    height: 100%;
}
.screenHeight {
    .login.changePosition{
        height: 105vh;
    }
    .progressContainer {
        margin-top: 30px;
        margin-bottom: 30px;

        @media screen and (min-height: 768px) {
            width: 60%;
            display: block;
            margin: auto;
            margin-top: 20px;
            padding-bottom: 20px;
        }

        p {
            margin-bottom: 8px;
        }

        .progress {
            height: 2px;
        }
    }

    .listItem {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 1rem 0.75rem;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.8;
        color: #707070;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .temMemDisable{
        display: none;
    }

    .addTeamMember {
        .signinLink {
            text-transform: uppercase;
            font-weight: 500;
            padding-top: 25px;
        }

        .signinLink.disable{
            color: #707070;
            pointer-events: none;
            cursor: default;
        }
    }

    .positionBottomButton.changePosition{
        position: relative;
        margin-top: 30px;
        bottom: auto;
        width: 100%;

        @media only screen and (min-width: 768px){
            margin-top: 0;
        }
    }

    .positionBottomLink.changePosition{
        position: relative;
    }

    .positionBottom.changePosition{
        position: relative;
        margin-top: 30px;
        bottom: auto;
    }

    .form-check-label {
        text-transform: uppercase;
        color: #707070;
        font-weight: 500;
        padding-bottom: 6px;
    }
}
</style>
