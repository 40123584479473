<template>
    <div>
        <div class="screenHeight" v-if="!step1 && !step2 && !step3">
            <div class="text-center">
                <img src="../assets/BRTopimage-01.png" alt="Balkan roads top image" class="topPictureMobile"/>
            </div>
            <section class="login">
                <img src="../assets/BalkanRoadsLogoSimbol.png" alt="Balkan roads top image" class="topPictureTablet"/>
                <div class="container">
                    <h1 class="headlinePhone pb-3 mb-1">Register</h1>
                    <h1 class="headlineTablet pb-3 mb-1">Register</h1>
                    <form @submit.prevent="stepOne">
                        <div class="form-group pb-3">
                            <input type="text" class="form-control" name="teamName" v-model="teamName"
                                   placeholder="Team name" required>
                        </div>

                        <div class="form-group pb-3">
                            <input type="text" class="form-control" name="name" v-model="username"
                                   placeholder="Your name" required>
                        </div>

                        <div class="form-group pb-3">
                            <input type="email" class="form-control" name="email" v-model="email"
                                   placeholder="Email address" required>
                        </div>

                        <div class="form-group pb-3 d-flex position-relative">
                            <input :type="visibility" class="form-control" v-model="password" placeholder="Password"
                                   name="new-password" required>
                            <a href="#" class="eyeIcon" @click="showPassword">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="15" viewBox="0 0 23 15">
                                    <defs>
                                        <clipPath id="clip-See_password">
                                            <rect width="23" height="15"/>
                                        </clipPath>
                                    </defs>
                                    <g id="See_password" data-name="See password" clip-path="url(#clip-See_password)">
                                        <g id="Group_904" data-name="Group 904" transform="translate(-562.089 12.853)">
                                            <path id="Path_1287" data-name="Path 1287"
                                                  d="M578.759-11.853h-9.541l-6.129,6.468L569.218.906h9.541l6.081-6.291Zm-2.485,9.277h-4.59l-2.447-2.857,2.447-2.943h4.59l2.418,2.943Z"
                                                  fill="#707070"/>
                                            <path id="Subtraction_6" data-name="Subtraction 6"
                                                  d="M571.677-5.45l1.184-1.706h2.221l1.17,1.706-1.17,1.659h-2.221Z"
                                                  fill="#707070"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>

                        <div class="form-group pb-3 d-flex position-relative">
                            <input :type="visibilityRepeatPass" class="form-control" v-model="passwordAgain"
                                   placeholder="Repeat password" name="repeat-password" required>
                            <a href="#" class="eyeIcon" @click="showRepeatPassword">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="15" viewBox="0 0 23 15">
                                    <defs>
                                        <clipPath id="clip-See_password">
                                            <rect width="23" height="15"/>
                                        </clipPath>
                                    </defs>
                                    <g id="See_password" data-name="See password" clip-path="url(#clip-See_password)">
                                        <g id="Group_904" data-name="Group 904" transform="translate(-562.089 12.853)">
                                            <path id="Path_1287" data-name="Path 1287"
                                                  d="M578.759-11.853h-9.541l-6.129,6.468L569.218.906h9.541l6.081-6.291Zm-2.485,9.277h-4.59l-2.447-2.857,2.447-2.943h4.59l2.418,2.943Z"
                                                  fill="#707070"/>
                                            <path id="Subtraction_6" data-name="Subtraction 6"
                                                  d="M571.677-5.45l1.184-1.706h2.221l1.17,1.706-1.17,1.659h-2.221Z"
                                                  fill="#707070"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>

                        <div class="form-group text-center">
                            <button class="btn btn-secondary w-100 text-uppercase">Sign up</button>
                        </div>
                    </form>
                    <div class="spacingDiv text-center">
                        I already have an account.
                        <router-link to="/signin" class="signinLink">Sign in</router-link>
                    </div>

                </div>
            </section>
            <!--<div class="positionBottom text-center pb-2"><a href="#" class="signinLink">Terms &#38; Conditions</a></div>-->
        </div>
        <step-one v-if="step1" @stepTwo="stepTwo"></step-one>
        <step-two v-if="step2" @stepThree="stepThree"></step-two>
        <step-three v-if="step3" @done="done"></step-three>
    </div>
</template>

<script>
import StepOne from '@/components/StepOne.vue';
import StepTwo from '@/components/StepTwo';
import StepThree from '@/components/StepThree.vue';
import axios from "axios";
import {REGISTER_URL} from '../config/variables';
import {useToast} from "vue-toastification";

export default {
    setup() {
        const toast = useToast();
        return {toast}
    },
    data() {
        return {
            step1: false,
            step2: false,
            step3: false,
            teamName: "",
            username: "",
            email: "",
            password: "",
            passwordAgain: "",
            phone: "",
            car_reg: "",
            carType: "",
            carColor: "",
            members: [],
            numOfNotifications: null,
            userData: null,
            errorMessage: '',
            visibility: 'password',
            visibilityRepeatPass: 'password',
        }
    },
    methods: {
        stepOne() {
            if (this.password.length < 6) {
                this.toast.error('Password must be at least 6 characters long.')
            } else if (this.password === this.passwordAgain) {
                this.step1 = true;
            } else {
                this.toast.error('Passwords doesn\'t match.');
            }
        },

        stepTwo(phone, car_reg, carType, carColor) {
            this.phone = phone;
            this.car_reg = car_reg;
            this.carType = carType;
            this.carColor = carColor;

            this.step1 = false;
            this.step2 = true;
        },

        stepThree(members) {
            this.members = members;
            this.step2 = false;
            this.step3 = true;
        },

        done(numOfNotifications) {
            this.numOfNotifications = numOfNotifications;

            this.userData = {
                team_name: this.teamName,
                name: this.username,
                email: this.email,
                //passwordAgain: this.password.passwordAgain,
                password: this.password,
                phone: this.phone,
                car_reg: this.car_reg,
                car_type: this.carType,
                car_color: this.carColor,
                members: this.members,
                message_level: this.numOfNotifications,
            }

            this.toast.info('Registration is finishing...', {
                timeout: 1800,
            });

            axios.post(REGISTER_URL, {
                name: this.username,
                email: this.email,
                password: this.password,
                password_confirmation: this.passwordAgain,
                phone: this.phone,
                car_reg: this.car_reg,
                car: this.carType,
                car_color: this.carColor,
                team_name: this.teamName,
                //members: this.members,
                message_level: this.numOfNotifications,
            })
                .then(() => {
                    this.$router.push({
                        name: 'SignIn',
                    })
                })
                .catch((error) => {
                    this.toast.error('Ooops, something is wrong, please check your data and try again!');
                    this.step3 = false;
                    console.log(error);
                });
        },

        showPassword() {
            if (this.visibility === 'text') {
                this.visibility = 'password'

            } else {
                this.visibility = 'text';
            }
        },

        showRepeatPassword() {
            if (this.visibilityRepeatPass === 'text') {
                this.visibilityRepeatPass = 'password'

            } else {
                this.visibilityRepeatPass = 'text';
            }
        },

    },
    components: {
        StepOne,
        StepTwo,
        StepThree
    }
}
</script>

<style lang="scss" scoped>
.screenHeight {
    @media screen and (max-height: 700px) {
        min-height: 700px;
    }
    @media screen and (min-width: 768px) {
        height: 100%;
        overflow-y: scroll;
    }

    .spacingDiv {
        margin-bottom: 80px;
    }

    .toBottom {
        position: relative;
        top: auto;
        bottom: auto;
    }

    .eyeIcon {
        position: absolute;
        right: 16px;
        margin-top: 16px;
        text-decoration: none;
    }
}
</style>
