<template>
    <div class="screenHeight">
        <section class="login">
            <img src="../assets/BalkanRoadsLogoSimbol.png" alt="Balkan roads top image" class="topPictureTablet"/>
            <div class="container">
                <div class="progressContainer">
                    <p>STEP 3 OF 3</p>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="100"
                             aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
                <div class="instantMessages">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="154"
                         height="107" viewBox="0 0 154 107">
                        <defs>
                            <clipPath id="clip-Instant_message_illustration">
                                <rect width="154" height="107"/>
                            </clipPath>
                        </defs>
                        <g id="Instant_message_illustration" data-name="Instant message illustration"
                           clip-path="url(#clip-Instant_message_illustration)">
                            <g id="Group_885" data-name="Group 885" transform="translate(2 2)">
                                <ellipse id="Ellipse_201" data-name="Ellipse 201" cx="7.111" cy="0.741" rx="7.111"
                                         ry="0.741" transform="translate(112.437 100.745)" fill="#e6e6e6"/>
                                <ellipse id="Ellipse_202" data-name="Ellipse 202" cx="7.111" cy="0.741" rx="7.111"
                                         ry="0.741" transform="translate(133.473 100.745)" fill="#e6e6e6"/>
                                <path id="Path_1242" data-name="Path 1242"
                                      d="M234.325,191.753h-107.4a1.294,1.294,0,0,1-1.293-1.293V122.13a1.294,1.294,0,0,1,1.293-1.293h107.4a1.294,1.294,0,0,1,1.293,1.293V190.46A1.294,1.294,0,0,1,234.325,191.753Zm-107.4-70.4a.782.782,0,0,0-.781.781V190.46a.782.782,0,0,0,.781.781h107.4a.782.782,0,0,0,.781-.781V122.13a.782.782,0,0,0-.781-.781Z"
                                      transform="translate(-125.629 -120.837)" fill="#e6e6e6"/>
                                <path id="Path_1243" data-name="Path 1243"
                                      d="M317.99,179.918H261.085a1.055,1.055,0,0,0-1.054,1.054v48.66a1.055,1.055,0,0,0,1.054,1.054H317.99a1.055,1.055,0,0,0,1.054-1.054v-48.66A1.055,1.055,0,0,0,317.99,179.918Zm.632,49.714a.634.634,0,0,1-.632.634H261.085a.633.633,0,0,1-.632-.634v-48.66a.633.633,0,0,1,.632-.634H317.99a.634.634,0,0,1,.632.634Z"
                                      transform="translate(-237.114 -169.844)" fill="#3f3d56"/>
                                <path id="Path_1244" data-name="Path 1244"
                                      d="M301.484,406.039c0,.075,0,.15-.006.223a4.292,4.292,0,0,1-8.573,0c0-.073-.006-.148-.006-.223a4.293,4.293,0,0,1,8.585,0Z"
                                      transform="translate(-264.378 -353.849)" fill="#00a1ed"/>
                                <path id="Path_1245" data-name="Path 1245"
                                      d="M406.457,410.135H372.728a.716.716,0,0,0,0,1.431h33.729a.716.716,0,1,0,0-1.431Z"
                                      transform="translate(-330.002 -360.807)" fill="#00a1ed"/>
                                <path id="Path_1246" data-name="Path 1246"
                                      d="M387.246,435.315H372.733a.715.715,0,0,0,0,1.429h14.513a.715.715,0,0,0,0-1.429Z"
                                      transform="translate(-330.007 -381.694)" fill="#e6e6e6"/>
                                <path id="Path_1247" data-name="Path 1247"
                                      d="M396.563,245.813H339.657a1.2,1.2,0,0,1-1.2-1.2V219.638a1.2,1.2,0,0,1,1.2-1.2h56.905a1.2,1.2,0,0,1,1.2,1.2v24.973A1.2,1.2,0,0,1,396.563,245.813Z"
                                      transform="translate(-302.167 -201.794)" fill="#00a1ed"/>
                                <path id="Path_1248" data-name="Path 1248"
                                      d="M409.99,270.355a.715.715,0,0,0,0,1.431h33.728a.715.715,0,0,0,0-1.431Z"
                                      transform="translate(-360.911 -244.861)" fill="#fff"/>
                                <path id="Path_1249" data-name="Path 1249"
                                      d="M409.99,294.668a.715.715,0,0,0,0,1.431h33.728a.715.715,0,0,0,0-1.431Z"
                                      transform="translate(-360.911 -265.028)" fill="#fff"/>
                                <path id="Path_1250" data-name="Path 1250"
                                      d="M409.99,318.685a.715.715,0,1,0,0,1.431H424.5a.715.715,0,0,0,0-1.431Z"
                                      transform="translate(-360.911 -284.95)" fill="#fff"/>
                                <g id="Group_542" data-name="Group 542">
                                    <circle id="Ellipse_204" data-name="Ellipse 204" cx="0.889" cy="0.889" r="0.889"
                                            transform="translate(3.7 2.749)" fill="#00a1ed"/>
                                    <circle id="Ellipse_205" data-name="Ellipse 205" cx="0.889" cy="0.889" r="0.889"
                                            transform="translate(6.811 2.749)" fill="#00a1ed"/>
                                    <circle id="Ellipse_206" data-name="Ellipse 206" cx="0.889" cy="0.889" r="0.889"
                                            transform="translate(9.922 2.749)" fill="#00a1ed"/>
                                </g>
                                <path id="Path_1251" data-name="Path 1251"
                                      d="M893.17,450.562l-.729,4.04a2.087,2.087,0,0,0,2.34,2.437h0a2.087,2.087,0,0,0,1.746-2.54l-.924-3.972,3.305-10.7-.762-11.454-4.735,1.637,2.141,8.758Z"
                                      transform="translate(-761.666 -385.933)" fill="#ffb8b8"/>
                                <circle id="Ellipse_207" data-name="Ellipse 207" cx="3.959" cy="3.959" r="3.959"
                                        transform="translate(125.606 24.432)" fill="#ffb8b8"/>
                                <path id="Path_1252" data-name="Path 1252"
                                      d="M873.979,361.217l7.739-.121c-1.66-4.018-3.006-7.812-2.66-10.4l-5.441.846C874.26,355.218,874.573,357.865,873.979,361.217Z"
                                      transform="translate(-746.078 -321.504)" fill="#ffb8b8"/>
                                <path id="Path_1253" data-name="Path 1253"
                                      d="M839.577,399.834l10.4,1.935c3.506-6.423,5.44-12.222,2.177-15.961l-6.167-.363c-3.94,4.214-7.273,8.3-5.009,11.246Z"
                                      transform="translate(-717.843 -350.327)" fill="#00a1ed"/>
                                <path id="Path_1254" data-name="Path 1254"
                                      d="M892.827,400.011c2.38.91,4.589.119,6.616-2.476l-3-5.8-3.829.759Z"
                                      transform="translate(-761.833 -355.547)" fill="#00a1ed"/>
                                <path id="Path_1255" data-name="Path 1255"
                                      d="M826.185,513.253l4.111-.211,2.66-27.841L846.5,512.89l4.232-.121-8.1-24.3c-.32-8.109-.654-16.2-4.111-19.709l-1.209-2.539-9.673-2.056-1.33,2.539C823.222,473.9,825.033,494.477,826.185,513.253Z"
                                      transform="translate(-705.418 -415.621)" fill="#2f2e41"/>
                                <path id="Path_1256" data-name="Path 1256"
                                      d="M812.83,751.52l4.8.46a1.944,1.944,0,0,0,2.119-2.141l-.353-3.315a8.339,8.339,0,0,1-4.232-.121l-1.814,1.572-.86.269a1.68,1.68,0,0,0-1.173,1.458h0A1.68,1.68,0,0,0,812.83,751.52Z"
                                      transform="translate(-694.396 -649.739)" fill="#2f2e41"/>
                                <path id="Path_1257" data-name="Path 1257"
                                      d="M931.968,751.52l4.8.46a1.944,1.944,0,0,0,2.119-2.141l-.353-3.315a8.339,8.339,0,0,1-4.232-.121l-1.814,1.572-.86.269a1.68,1.68,0,0,0-1.173,1.458h0A1.68,1.68,0,0,0,931.968,751.52Z"
                                      transform="translate(-793.219 -649.739)" fill="#2f2e41"/>
                                <path id="Path_1258" data-name="Path 1258"
                                      d="M858.015,392.217c1.694,1.9,4.019,2.217,7.013.846v-6.53l-3.748-1.088Z"
                                      transform="translate(-733.137 -350.327)" fill="#00a1ed"/>
                                <path id="Path_1259" data-name="Path 1259"
                                      d="M791.945,443.3l-2.506,3.251a2.087,2.087,0,0,0,.956,3.241h0a2.087,2.087,0,0,0,2.719-1.452l1.007-3.952,7.859-7.98,4.595-10.52-4.958-.725-3.386,9.19Z"
                                      transform="translate(-675.894 -383.268)" fill="#ffb8b8"/>
                                <path id="Path_1260" data-name="Path 1260"
                                      d="M871.333,307.373a7.877,7.877,0,0,1,5.925-.549,6.18,6.18,0,0,1,4.063,4.211,14.006,14.006,0,0,1-.028,4.591,6.7,6.7,0,0,0,.794,4.449,7.35,7.35,0,0,0,2.2,1.852,8.727,8.727,0,0,1,2.257,1.786,2.787,2.787,0,0,1,.564,2.7c-.464,1.14-1.8,1.6-2.984,1.939a20.662,20.662,0,0,1-5.154.992,6.957,6.957,0,0,1-4.875-1.615c-1.753-1.619-2.174-4.268-1.842-6.632a61.286,61.286,0,0,1,1.9-6.9,2.736,2.736,0,0,0-.279-2.624c-.659-.666-1.777-.535-2.6-.988a1.989,1.989,0,0,1,.559-3.689"
                                      transform="translate(-743.281 -284.823)" fill="#2f2e41"/>
                            </g>
                        </g>
                    </svg>

                    <p class="headline">Instant messages</p>
                    <p>Instant messages are short and sweet pop-up facts related to the places you’re driving nearby or
                        heading to. We aim to help you know some great stuff about the versatile culture, nature,
                        delicacies, people, cities and all the other facts that would be such a shame to miss and not
                        know about. Their purpose is to make your experience authentic and make you a full-on Balkan.
                        <strong>How many messages you want to get on daily basis?</strong></p>
                </div>
                <form @submit.prevent="done">
                    <div class="range-wrap">
                        <input type="range" class="form-range w-100" min="0" max="20" v-model="numOfNotifications"
                               id="customRange">
                        <output class="bubble" id="bubbleNotification"></output>
                    </div>

                    <div class="positionBottomButton form-group text-center">
                        <button class="btn btn-secondary w-100 text-uppercase">Done</button>
                    </div>
                </form>

            </div>
        </section>
        <!--<div class="positionBottom text-center pb-2"><a href="#" class="signinLink">Terms &#38; Conditions</a></div>-->
    </div>
</template>

<script>
export default {
    data() {
        return {
            numOfNotifications: 5,
        }
    },
    mounted() {
        this.makeBubbleNotification();
    },
    methods: {
        done() {
            this.$emit('done', this.numOfNotifications);
        },
        makeBubbleNotification() {
            const val = this.numOfNotifications;
            const min = 0;
            const max = 20;
            const newVal = Number(((val - min) * 100) / (max - min));
            const bubble = document.querySelector("#bubbleNotification");
            bubble.innerHTML = this.numOfNotifications;

            // Sorta magic numbers based on size of the native UI thumb
            bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
        }
    },
    watch: {
        numOfNotifications() {
            this.makeBubbleNotification();
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.screenHeight {
    height: 100%;
    overflow-y: scroll;

    .progressContainer {
        margin-top: 30px;
        margin-bottom: 30px;

        @media screen and (min-height: 768px) {
            width: 60%;
            display: block;
            margin: auto;
            margin-top: 20px;
            padding-bottom: 20px;
        }

        p {
            margin-bottom: 8px;
        }

        .progress {
            height: 2px;
        }
    }

    .instantMessages {
        @media screen and (min-height: 768px) {
            width: 60%;
            display: block;
            margin: auto;
        }

        svg {
            display: block;
            margin: auto;
            width: 130px;

            @media screen and (min-height: 768px) {
                width: 140px;
            }
        }

        .headline {
            color: #707070;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .positionBottom {
        position: relative;
        margin: 60px 0 20px 0;
    }

    .positionBottomButton {
        position: relative;
        margin: 50px 0 70px 0;
        width: 100%;
        bottom: auto;

        @media only screen and (min-width: 768px) {
            margin: 0;
        }
    }

    .positionBottomLink {
        position: relative;
        margin: 60px 0 20px 0;
        bottom: auto;
    }

    .range-wrap {
        position: relative;
        margin: 0 10px;

        .form-range {
            margin-top: 3px;
        }

        input[type='range'] {
            height: 5px;
        }

        input[type=range]::-webkit-slider-thumb {
            z-index: 2;
            position: relative;
            height: 24px;
            width: 22px;
            background-image: url("../assets/svg/Bar01.svg");
            background-repeat: no-repeat;
            background-color: transparent;
            cursor: pointer;
            -webkit-appearance: none;
            border: none;
            border-radius: 0;
            margin-top: -9px;
        }

        input[type=range]::-moz-range-thumb {
            z-index: 2;
            position: relative;
            height: 24px;
            width: 22px;
            background-image: url("../assets/svg/Bar01.svg");
            background-repeat: no-repeat;
            background-color: transparent;
            cursor: pointer;
            border: none;
            border-radius: 0;
            margin-top: -9px;
        }

        input[type=range]::-ms-thumb {
            z-index: 2;
            position: relative;
            height: 24px;
            width: 22px;
            background-image: url("../assets/svg/Bar01.svg");
            background-repeat: no-repeat;
            background-color: transparent;
            cursor: pointer;
            border: none;
            border-radius: 0;
            margin-top: -9px;
        }

        .bubble {
            background: transparent;
            color: #00A1ED;
            padding: 26px 12px;
            font-size: 18px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        ::-webkit-slider-runnable-track {
            background-color: $secondary;
            height: 3px;
        }

        input[type=range]:focus::-webkit-slider-runnable-track {
            background: #ccc;
        }

        ::-moz-range-progress {
            background-color: $secondary;
        }

        ::-ms-fill-lower {
            background-color: $secondary;
        }
    }
}
</style>
