<template>
<div class="screenHeight">
    <section class="login">
        <img src="../assets/BalkanRoadsLogoSimbol.png" alt="Balkan roads top image" class="topPictureTablet" />
        <div class="container">
            <div class="progressContainer">
                <p>STEP 1 OF 3</p>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" style="width: 33%" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <form @submit.prevent="stepTwo">
                <div class="form-group">
                    <label class="form-check-label" for="phoneNum">Phone</label>
                    <input type="text" class="form-control" id="phoneNum" name="phone" v-model="phone" placeholder="+385 00 00000" required>
                </div>

                <div class="form-group">
                    <label class="form-check-label" for="carReg">Car registration</label>
                    <input type="text" class="form-control" id="carReg" name="registration" v-model="car_reg" placeholder="RI 0000 AA" required>
                </div>

                <div class="form-group">
                    <label class="form-check-label" for="carType">Car type</label>
                    <input type="text" class="form-control" id="carType" v-model="carType" placeholder="Zastava Yugo '89" name="type" required>
                </div>

                <div class="form-group">
                    <label class="form-check-label" for="carColor">Car color</label>
                    <input type="text" class="form-control" id="carColor" v-model="carColor" placeholder="Red" name="color" required>
                </div>

                <div class="form-group text-center pt-4">
                    <button class="btn btn-secondary w-100 text-uppercase">NEXT STEP</button>
                </div>
            </form>

        </div>
    </section>
    <!--<div class="positionBottom text-center pb-2"><a href="#" class="signinLink">Terms &#38; Conditions</a></div>-->
</div>
</template>

<script>
export default {
    data() {
        return {
            phone: "",
            car_reg: "",
            carType: "",
            carColor: "",
        }
    },
    methods: {
        stepTwo(){
            this.$emit('stepTwo', this.phone, this.car_reg, this.carType, this.carColor);
        }
    }
}
</script>

<style lang="scss" scoped>
.screenHeight {
    @media screen and (min-width: 768px){
        height: 100%;
        overflow-y: scroll;
    }
    .progressContainer {
        margin-top: 30px;
        margin-bottom: 30px;

        @media screen and (min-height: 768px) {
            width: 60%;
            display: block;
            margin: auto;
            margin-top: 20px;
            padding-bottom: 20px;
        }

        p {
            margin-bottom: 8px;
        }

        .progress {
            height: 2px;
        }
    }

    .form-check-label {
        text-transform: uppercase;
        color: #707070;
        font-weight: 500;
        padding-bottom: 6px;
    }
}
</style>
